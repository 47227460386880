import React from "react";

const TagLine = () => {
  return (
    <div className="tagline">
      <span>
        Here, we will help you to shape and build your ideald body and
      </span>
      <br />
      <span>live up your life to fullest.</span>
    </div>
  );
};

export default TagLine;
